import React from "react";
import Layout from "../components/layout";
import Head from "../components/head";

const AboutPage = () => {
  return (
    <div>
      <Layout>
        <Head title="About" />
        <div class="section-page-header">
          <h2>About</h2>
        </div>
        <p>
          Since I was a kid, I’ve always enjoyed building things, but it was
          Microsoft FrontPage that introduced me to my passion for creating
          websites and apps. Early on I began creating websites, which to me
          seemed like a Choose Your Own Adventure, a way to create your own
          stories for anyone in the world to experience. Many years and products
          later, I still love telling stories by developing tools and apps that
          create wonderful experiences.
          <br />
          <br />
          I previously worked as a Senior Product Manager at iHeartRadio where I
          was the lead product manager for subscription services, podcasts and
          product marketing. I also contributed to much of iHeartRadio's success
          as a podcast platform having launched features like auto-download,
          cross-platform progress sync, offline support, real time notifications
          and much more.
          <br />
          <br />I sometimes moonlight as an engineer as well, in 2015 I single
          handedly built and launched{" "}
          <a href="https://blog.iheart.com/Pages/iheartradio-extension-google-chrome.aspx">
            iHeartRadio's Chrome Extension
          </a>{" "}
          (no longer online) selected as one of the best Chrome Extensions of
          the year by Google Chrome Web Store editors.
        </p>
      </Layout>
    </div>
  );
};

export default AboutPage;
